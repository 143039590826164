import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Show from "../../assets/img/icons/show.png";
import Hide from "../../assets/img/icons/hide.png";
import Info from "../../assets/img/icons/info.png";
import { showNotificationToast } from "../../helpers/Messages";
import { Tooltip } from "@mui/material";

const AuthPageResetConfirmForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [formError, setFormError] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onPassword2Change = (e) => {
    setPassword2(e.target.value);
  };

  const onResetConfirm = async (e) => {
    e.preventDefault();

    if (!username.trim()) {
      setFormError("Username is required");
      return;
    }

    const data_to_be_sent = {
      new_username: username,
      new_password: password,
      confirm_new_password: password2,
    };

    try {
      let result = await window.apiHelper.postResetPassword(data_to_be_sent);

      if (result) {
        setFormSubmitted(true);
      } else {
        setFormError("An error occurred while resetting the password");
      }
    } catch (error) {
      setFormError(error.response.data);
    }
  };

  if (formSubmitted) {
    showNotificationToast("Password changed succesfully!");
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      <form>
        <h1 className="auth-reset-header">Welcome to Detect!</h1>
        <p className="auth-reset-p">To complete your account setup, please add your name and create a secure password. This will be your password for future access. </p>

        <div className="form-group">
          <div className="name-label-styling">
            <label htmlFor="username-field" style={{ padding: 0 }}>
              Name
            </label>
            <Tooltip
              title="This name will be shown for chat messages"
              arrow
              placement="top"
            >
              <img
                src={Info}
                alt="reset"
                style={{ width: "17.5px", height: "17.5px", marginLeft: "5px" }}
              />
            </Tooltip>
          </div>
          <input
            type="text"
            name="username-field"
            value={username}
            className="form-control"
            id="username-field"
            onChange={onUsernameChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password-field">Password</label>
          <div style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"}
              name="password-field"
              value={password}
              className="form-control"
              id="password-field"
              onChange={onPasswordChange}
            />
            <img
              onClick={() => setShowPassword(!showPassword)}
              src={!showPassword ? Show : Hide}
              alt="edit"
              className="visibility-img"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="password-field-2">Confirm password</label>
          <div style={{ position: "relative" }}>
            <input
              type={showPassword2 ? "text" : "password"}
              name="password-field-2"
              value={password2}
              className="form-control"
              id="password-field-2"
              onChange={onPassword2Change}
            />
            <img
              onClick={() => setShowPassword2(!showPassword2)}
              src={!showPassword2 ? Show : Hide}
              alt="edit"
              className="visibility-img"
            />
          </div>
          {formError && (
            <div className="error-container">
              {typeof formError === "string" ? (
                <div className="error-message">• {formError}</div>
              ) : (
                Array.isArray(formError) &&
                formError.map((error, index) => (
                  <div key={index} className="error-message">
                    • {error}
                    <br />
                  </div>
                ))
              )}
            </div>
          )}
        </div>

        <div className="form-group">
          <button
            type="submit"
            className="btn btn-warning"
            onClick={onResetConfirm}
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default AuthPageResetConfirmForm;
