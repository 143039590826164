import React from "react";
import { Select, MenuItem, OutlinedInput } from "@mui/material";

const SingleDropdown = ({
    value,
    onChange,
    options = [],
    placeholder = "",
    inputStyles = {},
    menuStyles = {},
    displayEmpty = true,
    disabled = false,
}) => {
    return (
        <Select
            value={value}
            onChange={onChange}
            displayEmpty={displayEmpty}
            input={<OutlinedInput sx={{ ...inputStyles, fontFamily: "'Space Grotesk', sans-serif" }} />}
            disabled={disabled}
        >
            {displayEmpty && (
                <MenuItem value="" disabled>
                    {placeholder}
                </MenuItem>
            )}
            {options.map((option) => (
                <MenuItem
                    key={option.id}
                    value={option.value}
                    sx={{ ...menuStyles, fontFamily: "'Space Grotesk', sans-serif" }}
                >
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SingleDropdown;
