import React, { useState } from "react";
import Map, { Source, Layer } from "react-map-gl";
import Layers from "../../assets/img/icons/layers.png";
import Close from "../../assets/img/icons/cancel.png";
import Recenter from "../../assets/img/icons/recenter.png";
import Loader from "../../helpers/Loader";
import "simplebar-react/dist/simplebar.min.css";
import { mapStyles } from "../../helpers/Constants";
import MapStyleSelector from "../../helpers/MapStyleSelector";
import { LngLatBounds } from "mapbox-gl";

const BaseMapComponent = ({
  mapToolHeight,
  mapData,
  loading,
  innerRef,
  children,
  onVisibleMarkersChange,
  setMapZoom,
  showLayer: externalShowLayer,
  toggleLayer: externalToggleLayer,
  onClick,
  onDblClick,
  onMouseMove,
}) => {
  const [selectedLayer, setSelectedLayer] = useState(
    localStorage.getItem("selectedMapStyle") || "light"
  );
  const [internalShowLayers, setInternalShowLayers] = useState(false);

  const isLayerVisible =
    externalShowLayer !== undefined ? externalShowLayer : internalShowLayers;

  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 14,
  });
  const [currentZoom, setCurrentZoom] = useState(14);

  // Convert nearby_structures to GeoJSON format
  const getGeoJSONData = () => ({
    type: "FeatureCollection",
    features: mapData?.nearby_structures
      ? mapData.nearby_structures.map((structure) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [structure.lon, structure.lat],
          },
          properties: {
            identifier: structure.identifier,
            severity: structure.severity,
          },
        }))
      : mapData?.map((structure) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [structure?.location?.lon, structure?.location?.lat],
          },
        })) || [],
  });

  // Cluster and point layers
  const clusterLayer = {
    id: "clusters",
    type: "circle",
    source: "clusters",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": [
        "step",
        ["get", "point_count"],
        "#51bbd6", // Color for small clusters
        10,
        "#f1f075", // Medium clusters
        30,
        "#f28cb1", // Large clusters
      ],
      "circle-radius": [
        "step",
        ["get", "point_count"],
        20, // Radius for small clusters
        10,
        30, // Medium clusters
        30,
        40, // Large clusters
      ],
    },
  };

  const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "clusters",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  };

  const unclusteredPointLayer = {
    id: "unclustered-point",
    type: "circle",
    source: "clusters",
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-color": [
        "step",
        ["get", "severity"],
        "#27c59b", // Default color for no severity
        1,
        "#ff8039", // Medium severity
        2,
        "#ff4b4b", // High severity
      ],
      "circle-radius": 10,
    },
  };

  const handleShowLayers = () => {
    if (externalToggleLayer) {
      // Use external toggle function if provided
      externalToggleLayer();
    } else {
      // Otherwise, use internal state
      setInternalShowLayers((prev) => {
        const newState = !prev;
        if (newState) {
          localStorage.setItem("selectedMapStyle", selectedLayer);
        }
        return newState;
      });
    }
  };

  const updateVisibleMarkers = (bounds) => {
    const visible = mapData?.nearby_structures
      ? mapData.nearby_structures.filter((structure) =>
          bounds.contains([structure.lon, structure.lat])
        )
      : mapData?.filter((structure) =>
          bounds.contains([structure?.location?.lon, structure?.location?.lat])
        );

    onVisibleMarkersChange(visible || []);
  };

  const handleZoomChange = (evt) => {
    const { viewState } = evt;
    setViewport(viewState);
    setCurrentZoom(viewState.zoom.toFixed(2));
    setMapZoom(viewState.zoom.toFixed(2));
    if (innerRef.current) {
      const bounds = innerRef.current.getBounds();
      updateVisibleMarkers(bounds);
    }
  };

  const handleMoveEnd = () => {
    if (innerRef.current) {
      const bounds = innerRef.current.getBounds();
      updateVisibleMarkers(bounds);
    }
  };

  const handleMapLoad = () => {
    if (mapData && innerRef.current) {
      const { lon, lat } = mapData?.current_structure
        ? mapData?.current_structure
        : mapData[0]?.location;
      const bounds = new LngLatBounds(
        [lon - 0.00135, lat - 0.00135],
        [lon + 0.00135, lat + 0.00135]
      );
      innerRef.current.fitBounds(bounds, {
        animate: true,
        padding: 40,
        duration: 100,
      });
      updateVisibleMarkers(bounds);
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: mapToolHeight - 21,
          position: "relative",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <button className="layers-button" onClick={handleShowLayers}>
              <img
                src={!isLayerVisible ? Layers : Close}
                alt="layers"
                style={{ width: "25px", padding: "3px" }}
              />
            </button>
            <button className="recenter-button" onClick={handleMapLoad}>
              <img
                src={Recenter}
                alt="recenter"
                style={{ width: "25px", padding: "3px" }}
              />
            </button>

            {isLayerVisible && (
              <div className="map-style-selector">
                <MapStyleSelector
                  setSelectedLayer={setSelectedLayer}
                  selectedLayer={selectedLayer}
                />
              </div>
            )}

            <Map
              ref={innerRef}
              initialViewState={viewport}
              onMove={handleZoomChange}
              onLoad={handleMapLoad}
              style={{
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                borderRadius: "8px",
              }}
              mapStyle={mapStyles[selectedLayer].mapUrl}
              onMoveEnd={handleMoveEnd}
              attributionControl={false}
              onClick={onClick}
              onDblClick={onDblClick}
              onMouseMove={onMouseMove}
            >
              {currentZoom <= 15 && mapData && (
                <Source
                  id="clusters"
                  type="geojson"
                  data={getGeoJSONData()}
                  cluster={true}
                  clusterRadius={50}
                  clusterMaxZoom={14}
                >
                  <Layer {...clusterLayer} />
                  <Layer {...clusterCountLayer} />
                  <Layer {...unclusteredPointLayer} />
                </Source>
              )}
              {children}
            </Map>
          </>
        )}
      </div>
    </>
  );
};

export default BaseMapComponent;
