import React, { useState, useEffect } from "react";
import Header from "../../helpers/Header";
import TeamsPage from "./TeamsPage";
import RequestPage from "./RequestPage";

const SettingsPage = () => {
  const buttons = ["Teams", "Request"];
  const [activeButton, setActiveButton] = useState(buttons[0]);

  useEffect(() => {
    window.appComponent.setState({ loaderVisible: false });
  }, []);

  return (
    <div className="settings-view">
      <Header middleHeaderComponent={"Settings"} />
      <div className="settings-content">
        <div className="toggle-buttons">
          {buttons.map((button) => (
            <button
              key={button}
              className={`toggle-button ${
                activeButton === button ? "active" : ""
              }`}
              onClick={() => setActiveButton(button)}
            >
              {button}
            </button>
          ))}
        </div>

        <div className="content-display">
          {activeButton === "Teams" && <TeamsPage />}
          {activeButton === "Request" && <RequestPage />}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
