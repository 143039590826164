import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  TextField,
  Box,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import MultiInputForm from "../../Components/Forms/MultiInputForm";
import SingleDropdown from "../../Components/Dropdown/SingleDropdown";
import AutocompleteForm from "../../Components/Forms/AutocompleteForm";
import { showNotificationToast } from "../../helpers/Messages";
import Loader from "../../helpers/Loader";

const PopupInternal = ({
  internalEmails,
  setInternalEmails,
  onClose,
  rolesList,
  setTeamsTableLoad,
}) => {
  const [newEmail, setNewEmail] = useState("");
  const [role, setRole] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [changesLoad, setChangesLoad] = useState(false);

  const user_org = localStorage.getItem("user_org");

  const isDisabled = internalEmails.length === 0 || !roleId;

  const getTooltipMessage = () => {
    let message = "";

    if (internalEmails.length === 0) {
      message += "Please add at least one email to invite. ";
    }

    if (!roleId) {
      message += "Please select a role before sending invites. ";
    }

    return message.trim();
  };

  const handleAddEmail = (email) => {
    if (email.trim() && !internalEmails.includes(email.trim())) {
      setInternalEmails([...internalEmails, email.trim()]);
      setNewEmail("");
    }
  };

  const handleRemoveEmail = (index) => {
    setInternalEmails(internalEmails.filter((_, i) => i !== index));
  };

  const fetchOrgList = async () => {
    try {
      const getResponse = await window.apiHelper.getOrgList();

      if (getResponse) {
        setOrgs(getResponse);
        const preselected = getResponse.find((org) => org.name === user_org);
        setOrg(preselected || null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    user_org === "detect" && fetchOrgList();
  }, []);

  const sendInternalInvite = async (data) => {
    setChangesLoad(true);
    try {
      const postResponse = await window.apiHelper.postInternalInvite(data);

      if (postResponse) {
        onClose();
        showNotificationToast(`Sent invites successfully`);
        setTeamsTableLoad(true);
        setChangesLoad(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChangesLoad(false);
    }
  };

  const handleSendInternalInvites = () => {
    const data_to_be_sent = {
      emails: internalEmails,
      org_id: org.id,
      group_id: roleId,
    };
    sendInternalInvite(data_to_be_sent);
  };

  return (
    <div className="internal-body">
      <div className="internal-content">
        <div className="internal-left-content">
          {/* Email Input with Chips */}
          <div className="edit-user-details">
            <div className="edit-user-details-text">Emails :</div>
            <MultiInputForm
              items={internalEmails}
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Add emails"
              onAddItem={handleAddEmail}
              onRemoveItem={handleRemoveEmail}
            />
          </div>
          <div className="edit-user-details">
            <div className="edit-user-details-text">Org :</div>
            {user_org === "detect" ? (
              <AutocompleteForm
                value={org}
                onChange={(event, newValue) => {
                  setOrg(newValue);
                }}
                options={orgs}
                placeholder="Select an organization"
              />
            ) : (
              <TextField
                value={user_org}
                disabled
                sx={{ width: `${user_org.length + 2}ch` }}
                InputProps={{
                  sx: {
                    fontFamily: "'Space Grotesk', sans-serif",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontFamily: "'Space Grotesk', sans-serif",
                  },
                }}
              />
            )}
          </div>

          {/* Role Selection Section */}
          <div className="edit-user-details">
            <div className="edit-user-details-text">Role:</div>
            <SingleDropdown
              value={role}
              onChange={(e) => {
                const selectedRole = rolesList.find(
                  (roleOption) => roleOption.value === e.target.value
                );
                setRole(selectedRole.value);
                setPermissions(selectedRole.permissions);
                setRoleId(selectedRole.id);
              }}
              options={rolesList}
              placeholder="Select Role"
            />
          </div>

          {/* Permissions Section */}
          {role && (
            <div className="edit-user-details">
              <div className="edit-user-details-text">Permissions:</div>
              <OutlinedInput
                readOnly
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                  padding: "8px",
                  alignItems: "flex-start",
                  minHeight: "56px",
                  fontFamily: "'Space Grotesk', sans-serif"
                }}
                inputProps={{ style: { display: "none" } }}
                value=""
                startAdornment={
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    {permissions.map((permission) => (
                      <Chip
                        key={permission}
                        label={permission}
                        sx={{
                          backgroundColor: "#CFE2FB",
                          cursor: "pointer",
                          fontSize: "15px",
                          padding: "8px",
                          height: "32px",
                          fontFamily: "'Space Grotesk', sans-serif"
                        }}
                      />
                    ))}
                  </Box>
                }
              />
            </div>
          )}
        </div>
        <div className="internal-right-content"></div>
      </div>
      <div className="internal-footer">
        <button onClick={onClose} className="close-button-settings">
          Cancel
        </button>
        <Tooltip title={getTooltipMessage()} arrow placement="top">
          <button
            onClick={handleSendInternalInvites}
            className="save-button-settings"
            disabled={isDisabled}
          >
            {changesLoad ? (
              <div>
                <Loader />
              </div>
            ) : (
              "Send Invites"
            )}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default PopupInternal;
