// External.js
import React, { useState } from "react";
import { OutlinedInput, Chip, Box, Tooltip } from "@mui/material";
import { showNotificationToast } from "../../helpers/Messages";
import Loader from "../../helpers/Loader";
import MultiInputForm from "../../Components/Forms/MultiInputForm";
import SingleTextForm from "../../Components/Forms/SingleTextForm";

const PopupExternal = ({ externalEmails, setExternalEmails, onClose }) => {
  const [newEmail, setNewEmail] = useState("");
  const [org, setOrg] = useState("");
  const [newRequestId, setNewRequestId] = useState("");
  const [requestIds, setRequestIds] = useState([]);
  const [changesLoad, setChangesLoad] = useState(false);

  const disabled = externalEmails.length === 0 || requestIds.length === 0 || !org

  const getTooltipMessage = () => {
    let message = "";

    if (externalEmails.length === 0) {
      message += "Please add at least one email to invite. ";
    }

    if (!org) {
      message += "Please select an Org before sending invites. ";
    }

    if (requestIds.length === 0) {
      message += "Please select request IDs before sending invites. "
    }

    return message.trim();
  };

  const handleAddEmail = (email) => {
    if (email.trim() && !externalEmails.includes(email.trim())) {
      setExternalEmails([...externalEmails, email.trim()]);
      setNewEmail("");
    }
  };

  const handleRemoveEmail = (index) => {
    setExternalEmails(externalEmails.filter((_, i) => i !== index));
  };

  const handleAddRequestId = (id) => {
    if (id.trim() && !requestIds.includes(id.trim())) {
      setRequestIds([...requestIds, id.trim()]);
      setNewRequestId("");
    }
  };

  const handleRemoveRequestId = (index) => {
    setRequestIds(requestIds.filter((_, i) => i !== index));
  };

  const sendExternalInvite = async (data) => {
    setChangesLoad(true);
    try {
      const postResponse = await window.apiHelper.postExternalInvite(data);

      if (postResponse) {
        onClose();
        showNotificationToast(`Sent invites successfully`);
        setChangesLoad(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChangesLoad(false);
    }
  };

  const handleSendExternalInvites = () => {
    const data_to_be_sent = {
      emails: externalEmails,
      request_id: requestIds,
      org_name: org,
    };

    sendExternalInvite(data_to_be_sent);
  };

  return (
    <div className="external-body">
      <div className="external-content">
        <div className="external-left-content">
          <div className="edit-user-details">
            <div className="edit-user-details-text">Emails :</div>
            <MultiInputForm
              items={externalEmails}
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Add emails"
              onAddItem={handleAddEmail}
              onRemoveItem={handleRemoveEmail}
            />
          </div>

          <div className="edit-user-details">
            <div className="edit-user-details-text">Org :</div>
            <SingleTextForm
              value={org}
              onChange={(e) => setOrg(e.target.value)}
              placeholder="Enter Org"
            />
          </div>
          <div className="edit-user-details">
            <div className="edit-user-details-text">Request IDs :</div>
            <MultiInputForm
              items={requestIds}
              value={newRequestId}
              onChange={(e) => setNewRequestId(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddRequestId(newRequestId);
                }
              }}
              placeholder="Add Request IDs"
              onAddItem={handleAddRequestId}
              onRemoveItem={handleRemoveRequestId}
            />
          </div>
        </div>
        <div className="external-right-content"></div>
      </div>
      <div className="external-footer">
        <button onClick={onClose} className="close-button-settings">
          Cancel
        </button>
        <Tooltip title={getTooltipMessage()} arrow placement="top">
          <button
            onClick={handleSendExternalInvites}
            className="save-button-settings"
            disabled={
              disabled
            }
          >
            {changesLoad ? (
              <div>
                <Loader />
              </div>
            ) : (
              "Send Invites"
            )}
          </button>
        </Tooltip>
      </div>
    </div >
  );
};

export default PopupExternal;
