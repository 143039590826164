import React from "react";
import { OutlinedInput } from "@mui/material"

const SingleTextForm = ({
    value,
    onChange,
    placeholder,
    formControlStyles
}) => {
    return (
        <OutlinedInput
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            sx={{
                maxWidth: "100%", ...formControlStyles,
                fontFamily: "'Space Grotesk', sans-serif"
            }}
        />
    )
}

export default SingleTextForm

