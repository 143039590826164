import React, { useEffect, useState } from "react";
import Header from "../../helpers/Header";
import InspectionsDropdown from "../../helpers/InspectionsDropdown";
import { useNavigate, useParams } from "react-router-dom";
import DistributionMap from "./DistributionMap";

const DistributionViewPage = () => {
  const { id: paramId } = useParams();
  const navigate = useNavigate();

  const [showInspectionsDropdown, setShowInspectionsDropdown] = useState(false);
  const [activeButton, setActiveButton] = useState("Distribution");
  const [id, setId] = useState(paramId);
  const [data, setData] = useState({});

  const updateRemoteDate = async () => {
    try {
      const fetchedData = await window.apiHelper.getWorkorderInfo(id);
      document.title =
        fetchedData.linenumber + " — " + process.env.REACT_APP_PAGE_TITLE;

      setData(fetchedData);
    } catch (err) {
      if (err.response?.status === 404) {
        navigate("/accessdenied");
      }
    } finally {
      window.appComponent.setState({
        loaderVisible: false,
      });
    }
  };

  useEffect(() => {
    updateRemoteDate();
  }, []);

  const toggleDropdownInspections = () => {
    setShowInspectionsDropdown((prev) => !prev);
  };

  const handleActiveButton = (activeButton) => {
    if (activeButton === "Map") {
      navigate("/inspections" + "/" + id);
    } else if (activeButton === "Tracking") {
      navigate("/tracking" + "/" + id);
    }
  };

  let displayText = data.linenumber
    ? `${data.linenumber} / ${id} / `
    : "Loading... ";

  return (
    <div className="inspections-view">
      <Header
        middleHeaderComponent={
          <InspectionsDropdown
            activeButtonSelected={handleActiveButton}
            toggleDropdownInspections={toggleDropdownInspections}
            showInspectionsDropdown={showInspectionsDropdown}
            activeButton={activeButton}
            displayName={displayText}
          />
        }
        navigateBack={"inspectionsList"}
      />
      <div className="distribution-map-box">
        <DistributionMap id={paramId} />
      </div>
    </div>
  );
};

export default DistributionViewPage;
