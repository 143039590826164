import React, { useEffect, useState } from "react";
import {
  Drawer,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Box,
  Chip,
  OutlinedInput,
  Autocomplete,
} from "@mui/material";
import Loader from "../../helpers/Loader";
import { showNotificationToast } from "../../helpers/Messages";

const EditUserDrawer = ({
  drawerOpen,
  selectedUser,
  handleDrawerClose,
  handleFieldChange,
  handleRequestIdChange,
  rolesList,
  setUpdateUserData,
  setSelectedUser,
}) => {
  const [requestList, setRequestList] = useState([]);
  const [requestLoad, setRequestLoad] = useState(false);
  const [changesLoad, setChangesLoad] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    setRequestLoad(true);
    try {
      setRequestLoad(true);
      const requestIds = await window.apiHelper.getRequests();
      setRequestList(requestIds);
    } catch (error) {
      console.log(error);
    } finally {
      setRequestLoad(false);
    }
  };

  const patchData = async (org_id, user_id, data, email) => {
    setChangesLoad(true);
    try {
      const patchResponse = await window.apiHelper.getTeamUserUpdateStatus(
        org_id,
        user_id,
        data
      );

      if (patchResponse) {
        setUpdateUserData(true);
        handleDrawerClose();
        showNotificationToast(`data for ${email} updated successfully`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChangesLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveChanges = () => {
    const data_to_be_sent = {
      role: selectedUser?.role,
      active: selectedUser?.active,
      request_ids: selectedUser?.request_ids,
    };

    patchData(
      localStorage.getItem("user_org_id"),
      selectedUser.id,
      data_to_be_sent,
      selectedUser.email
    );
  };

  const handleIdDelete = (id) => {
    setSelectedUser((prev) => ({
      ...prev,
      request_ids: prev.request_ids.filter((requestId) => requestId !== id),
    }));
  };

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
      {selectedUser && (
        <Box className="edit-box">
          <div>
            <div className="edit-user-details">
              <div className="edit-user-details-text">Email :</div>
              <TextField
                value={selectedUser.email}
                disabled
                sx={{ width: `${selectedUser.email.length + 5}ch` }}
              />
            </div>
            <div className="edit-user-details">
              <div className="edit-user-details-text">Org :</div>
              <TextField
                value={selectedUser.org}
                disabled
                sx={{ width: `${selectedUser.org.length + 2}ch` }}
              />
            </div>
            <div className="edit-user-details">
              <div className="edit-user-details-text">Role :</div>
              <Select
                value={selectedUser?.role || "o"}
                onChange={(e) => handleFieldChange("role", e.target.value)}
              >
                {rolesList.map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {selectedUser?.role && (
              <div className="edit-user-details">
                <span className="edit-user-details-text">Permissions :</span>
                <OutlinedInput
                  readOnly
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    padding: "8px",
                    alignItems: "flex-start",
                    minHeight: "56px",
                  }}
                  inputProps={{ style: { display: "none" } }}
                  value=""
                  startAdornment={
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      {rolesList
                        .filter((role) => role.label === selectedUser?.role)[0]
                        .permissions.map((permission) => (
                          <Chip
                            key={permission}
                            label={permission}
                            sx={{
                              backgroundColor: "#CFE2FB",
                              cursor: "pointer",
                              fontSize: "15px",
                              padding: "8px",
                              height: "32px",
                            }}
                          />
                        ))}
                    </Box>
                  }
                />
              </div>
            )}
            <div className="edit-user-details">
              <div className="edit-user-details-text">Active :</div>
              <Checkbox
                checked={selectedUser.active}
                onChange={(e) => handleFieldChange("active", e.target.checked)}
              />
            </div>
            <div className="edit-user-details">
              <div className="edit-user-details-text">Request ID's :</div>
              {requestLoad ? (
                <Loader />
              ) : (
                <Autocomplete
                  multiple
                  options={requestList}
                  value={
                    selectedUser.request_ids
                      .map((id) =>
                        requestList.find((request) => request.id === id)
                      )
                      .filter(Boolean) // Exclude undefined values
                  }
                  onChange={(event, newValue) => {
                    const ids = newValue.map((item) => item.id);
                    handleRequestIdChange({ target: { value: ids } });
                  }}
                  getOptionLabel={(option) => option?.id?.toString() || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Search or select requests"
                      onFocus={handleOpen}
                      onBlur={handleClose}
                      sx={{ width: "500px" }}
                    />
                  )}
                  renderTags={(selected, getTagProps) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        maxHeight: "200px",
                        overflowY: "auto",
                      }}
                    >
                      {selected.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            key={option.id}
                            label={option.id}
                            onDelete={() => handleIdDelete(option.id)}
                            {...tagProps}
                            sx={{ fontSize: "15px" }}
                          />
                        );
                      })}
                    </Box>
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.id}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableClearable={true}
                  open={open}
                  sx={{
                    ".MuiAutocomplete-inputRoot": {
                      maxHeight: "250px",
                      overflowY: "auto",
                    },
                  }}
                />
              )}
            </div>
          </div>
          <div className="edit-box-footer">
            <button
              onClick={handleDrawerClose}
              className="close-button-settings"
            >
              Close
            </button>
            <button
              onClick={handleSaveChanges}
              className="save-button-settings"
            >
              {changesLoad ? (
                <div>
                  <Loader />
                </div>
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </Box>
      )}
    </Drawer>
  );
};

export default EditUserDrawer;
