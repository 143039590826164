import React, { useEffect, useState } from "react";
import TeamsTable from "./TeamsTable";
import PopupModal from "./PopupModal";
import Dropdown from "../../helpers/Dropdown";

const TeamsPage = () => {
  const currentUserOrg = localStorage.getItem("user_org");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [role, setRole] = useState([]);
  const [org, setOrg] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [orgList, setOrgList] = useState([]);
  const [rolesList, setRolesList] = useState([]);

  const [teamsTableLoad, setTeamsTableLoad] = useState(false);

  const fetchData = async () => {
    try {
      const orgList = await window.apiHelper.orderGetUtilityProviders();
      const orgListArray = orgList.map(({ name }) => ({
        label: name,
        value: name,
      }));

      setOrgList(orgListArray);
      const rolesList = await window.apiHelper.getRoles();
      const rolesListArray = rolesList.map(({ name, permissions, id }) => ({
        label: name,
        value: name,
        permissions: permissions,
        id: id,
      }));
      setRolesList(rolesListArray);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRoleSelect = (selectedRole) => {
    setRole(selectedRole);
  };

  const handleOrgSelect = (selectedOrg) => {
    setOrg(selectedOrg);
  };

  const onSearch = (e) => {
    e.preventDefault();
    const searchTerm = document.querySelector("input#annotation-search").value;
    setSearchText(searchTerm);
  };

  return (
    <div className="teams-view">
      {isModalOpen && (
        <PopupModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          rolesList={rolesList}
          setTeamsTableLoad={setTeamsTableLoad}
        />
      )}
      <div>
        <button className="invite-button" onClick={() => setIsModalOpen(true)}>
          Invite team member
        </button>
      </div>
      <div className="teams-table-filter">
        <form
          action=""
          autoComplete="off"
          className="form-group search-group search"
          onSubmit={onSearch}
          onBlur={onSearch}
        >
          <input
            type="text"
            className="form-control"
            id="annotation-search"
            placeholder="Search..."
            autoComplete="off"
          />
          <button>
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.0058 14C5.13982 14 2.0058 10.866 2.0058 6.99997C2.0058 3.13396 5.13979 0 9.0058 0C12.8718 0 16.0058 3.13399 16.0058 7C16.0058 10.866 12.8718 14 9.0058 14ZM9.0058 2.00001C6.24437 2.00001 4.00581 4.23857 4.00581 7C4.00581 9.76143 6.24437 12 9.0058 12C11.7672 12 14.0058 9.76143 14.0058 7C14.0058 4.23857 11.7672 2.00001 9.0058 2.00001Z"
                fill="#CACACA"
              />
              <path
                d="M1.0058 16C0.739987 16.0015 0.484514 15.8972 0.295804 15.71C-0.0963325 15.321 -0.0989176 14.6879 0.290014 14.2958C0.291944 14.2938 0.293874 14.2919 0.295804 14.29L4.05579 10.53C4.46172 10.1379 5.10867 10.1491 5.50081 10.555C5.89295 10.9609 5.88174 11.6079 5.47582 12L1.7158 15.71C1.52709 15.8972 1.27161 16.0015 1.0058 16Z"
                fill="#CACACA"
              />
            </svg>
          </button>
        </form>
        {currentUserOrg === "detect" && (
          <div className="button-dropdown-container">
            <Dropdown
              options={orgList}
              optionSelected={handleOrgSelect}
              selectedOptions={org}
              label="Org"
            />
          </div>
        )}
        <div className="button-dropdown-container">
          <Dropdown
            options={rolesList}
            optionSelected={handleRoleSelect}
            selectedOptions={role}
            label="Role"
          />
        </div>
      </div>

      <TeamsTable
        searchText={searchText}
        role={role}
        org={org}
        rolesList={rolesList}
        teamsTableLoad={teamsTableLoad}
      />
    </div>
  );
};

export default TeamsPage;
