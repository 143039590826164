import React, { useState, useEffect } from "react";
import { Drawer, Box, Chip, Autocomplete, TextField } from "@mui/material";
import { showNotificationToast } from "../../helpers/Messages";
import Loader from "../../helpers/Loader";

const EditRequestDrawer = ({
  open,
  onClose,
  editedUsers,
  handleDeleteUser,
  handleAddEmail,
  editableRow,
  setEditableRow,
  setUpdateUserData,
}) => {
  const [changesLoad, setChangesLoad] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [usersLoad, setUsersLoad] = useState(false);

  const [openList, setOpenList] = useState(false);

  const handleOpen = () => {
    setOpenList(true);
  };

  const handleClose = () => {
    setOpenList(false);
  };
  const fetchData = async () => {
    setUsersLoad(true);
    try {
      const requestIds = await window.apiHelper.getUsers();
      setUsersList(requestIds);
    } catch (error) {
      console.log(error);
    } finally {
      setUsersLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const patchData = async (org_id, request_id, data) => {
    setChangesLoad(true);
    try {
      const patchResponse = await window.apiHelper.getRequestRowUpdateStatus(
        org_id,
        request_id,
        data
      );

      if (patchResponse) {
        setUpdateUserData(true);
        setEditableRow(null);
        showNotificationToast(`data for ${request_id} updated successfully`);
        onClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChangesLoad(false);
    }
  };

  const handleSave = () => {
    const data_to_be_sent = {
      users: editedUsers,
    };
    patchData(
      localStorage.getItem("user_org_id"),
      editableRow.id,
      data_to_be_sent
    );
  };
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box className="edit-box">
        <div className="edit-user-details">
          <div className="edit-user-details-text">Users :</div>
          {usersLoad ? (
            <Loader />
          ) : (
            <Autocomplete
              multiple
              options={usersList}
              value={editableRow.users.map((email) => {
                const user = usersList.find((user) => user.email === email);
                return user || { id: email, email };
              })}
              onChange={(event, newValue) => {
                const newEmails = newValue.map((item) => item.email);
                const addedEmails = newEmails.filter(
                  (email) => !editableRow.users.includes(email)
                );
                const removedEmails = editableRow.users.filter(
                  (email) => !newEmails.includes(email)
                );

                addedEmails.forEach((email) => handleAddEmail(email));

                removedEmails.forEach((email) =>
                  handleDeleteUser(editableRow.users.indexOf(email))
                );

                setEditableRow((prev) => ({
                  ...prev,
                  users: newEmails,
                }));
              }}
              getOptionLabel={(option) => option?.email || ""}
              renderTags={(selected, getTagProps) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {selected.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        key={option.id}
                        label={option.email}
                        onDelete={() =>
                          handleDeleteUser(
                            editableRow.users.indexOf(option.email)
                          )
                        }
                        {...tagProps}
                        sx={{ fontSize: "15px" }}
                      />
                    );
                  })}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search or select users"
                  onFocus={handleOpen}
                  onBlur={handleClose}
                  sx={{ width: "450px" }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.email}
                </li>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable={true}
              open={openList}
              sx={{
                ".MuiAutocomplete-inputRoot": {
                  maxHeight: "250px",
                  overflowY: "auto",
                },
              }}
            />
          )}
        </div>
        <div className="edit-box-footer">
          <button onClick={onClose} className="close-button-settings">
            Close
          </button>
          <button onClick={handleSave} className="save-button-settings">
            {changesLoad ? (
              <div>
                <Loader />
              </div>
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      </Box>
    </Drawer>
  );
};

export default EditRequestDrawer;
