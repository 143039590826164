import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      overflowY: "auto",
    },
  },
};

const Dropdown = ({ options, optionSelected, selectedOptions, label }) => {
  let theme = useTheme();
  theme = {
    ...theme,
    typography: {
      ...theme.typography,
      fontFamily: "'Space Grotesk', sans-serif",
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const selected = typeof value === "string" ? value.split(",") : value;
    optionSelected(selected);
  };

  // Function to handle ellipsis
  const renderSelectedItems = (selected) => {
    const maxVisibleItems = 1; // Number of items to show before ellipsis
    const totalSelected = selected.length;
    const visibleItems = selected.slice(-maxVisibleItems); // Last few selected items
    const hiddenItemsCount = totalSelected - maxVisibleItems;

    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {visibleItems.map((value) => (
          <Chip
            key={value}
            label={options.find((opt) => opt.value === value)?.label || value}
          />
        ))}
        {hiddenItemsCount > 0 && (
          <Chip label={`+${hiddenItemsCount}`} color="default" />
        )}
      </Box>
    );
  };

  return (
    <FormControl sx={{ m: 1, width: 250, margin: 0 }}>
      <InputLabel
        id="multiple-select-checkbox-label"
        sx={{
          fontFamily: "'Space Grotesk', sans-serif",
          lineHeight: "16px",
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="multiple-select-checkbox-label"
        id="multiple-select-checkbox"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-checkbox" label={label} />}
        renderValue={renderSelectedItems}
        MenuProps={MenuProps}
        sx={{
          fontFamily: "'Space Grotesk', sans-serif",
          height: "45px",
          borderRadius: "10px",
          bgcolor: "white",
        }}
      >
        {options.map((option, index) => {
          // Check if option is an object or a string
          const isObject = typeof option === "object" && option !== null;
          const value = isObject ? option.value : option;
          const label = isObject ? option.label : option;

          return (
            <MenuItem
              key={index} // Use index for unique key
              value={value}
              style={{
                fontWeight:
                  selectedOptions.indexOf(value) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
                fontFamily: "'Space Grotesk', sans-serif",
              }}
            >
              <Checkbox checked={selectedOptions.indexOf(value) > -1} />
              {isObject ? (
                <>
                  {option.backgroundColor && option.color ? (
                    <Chip
                      label={label}
                      sx={{
                        backgroundColor: option.backgroundColor,
                        color: option.color,
                        fontWeight: 500,
                        fontSize: "13.5px",
                        fontFamily: "'Space Grotesk', sans-serif",
                      }}
                    />
                  ) : (
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        sx: { fontFamily: "'Space Grotesk', sans-serif" },
                      }}
                    />
                  )}
                  {option.icon && (
                    <img
                      src={option.icon}
                      alt={`${option.label} Icon`}
                      style={{
                        transform: `rotate(${option.rotation || 0}deg)`,
                        width: "27.5px",
                        marginRight: "8px",
                      }}
                    />
                  )}
                </>
              ) : (
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{
                    sx: { fontFamily: "'Space Grotesk', sans-serif" },
                  }}
                />
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
