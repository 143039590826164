import React, { Component } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import AuthProvider from "./pages/auth/AuthProvider";
import RequireAuth from "./pages/auth/RequireAuth";

import AuthPage from "./pages/auth/AuthPage";
import MainContentLayout from "./Components/MainContent/MainContentLayout";
import InspectionsListPage from "./pages/inspections/InspectionsListPage";
import InspectionViewPage from "./pages/inspections/InspectionViewPage";
import ReportViewPage from "./pages/reports/ReportViewPage";
import AuthHelper from "./helpers/AuthHelper";
import AuthPageLoginForm from "./pages/auth/AuthPageLoginForm";
import AuthPageLogout from "./pages/auth/AuthPageLogout";
import AuthPageResetForm from "./pages/auth/AuthPageResetForm";
import AuthPageResetConfirmForm from "./pages/auth/AuthPageResetConfirmForm";
import AuthPageRegistrationForm from "./pages/auth/AuthPageRegistrationForm";
import ReportsListPage from "./pages/reports/ReportsListPage";

import OrderCreatePage from "./pages/orders/OrderCreatePage";
import GCPImagesUploadPage from "./pages/misc/GCPImagesUploadPage";
import GuestSession from "./Components/Universal/GuestSession";
import ImagesListPage from "./pages/images/ImagesListPage";
import AnnotationDetails from "./pages/inspections/AnnotationDetails";
import { GlobalProvider } from "./GlobalContext";
import { getToken } from "firebase/messaging";
import { messaging } from "./helpers/Firebase";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TrackingViewPage from "./pages/inspections/TrackingViewPage";
import SettingsPage from "./pages/iam/SettingsPage";
import NoMatchPage from "./helpers/NoMatchPage";
import NoAccessPage from "./helpers/NoAccessPage";
import DistributionViewPage from "./pages/distributions/DistributionViewPage";

class App extends Component {
  constructor(props) {
    super(props);

    this.authHelper = new AuthHelper();

    window.appComponent = this;

    this.state = {
      user: this.authHelper.initUserData(),
      panelTypesAvailable: [],
      project: {},
      popupPosition: {
        top: 0,
        left: 0,
      },
      isMobile: window.innerWidth <= 1199,
      popupVisible: false,
      mobileMapVisible: false,
      loaderVisible: true,
    };

    this.mapComponent = React.createRef();

    this.onPopupToggle = this.onPopupToggle.bind(this);
    this.onPopupCoverClick = this.onPopupCoverClick.bind(this);
    this.onPopupCoverClick = this.onPopupCoverClick.bind(this);
    this.onMapTogglerClick = this.onMapTogglerClick.bind(this);
  }

  async requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      //generate token
      const token = await getToken(messaging, {
        vapidKey:
          "BHTZEQ_6z9D3eXfe_UW24tAgdr4Po0ypE8p9X6RqC_WKVkPTGShbKPI5BTVOTsi3M1ruetn-kgz9V454IyLBsWc",
      });

      const data = {
        registration_id: token,
        type: "web",
      };

      if (window.location.pathname.indexOf("/auth") === -1) {
        window.apiHelper
          .postDeviceRequestId(data)
          .then((result) => {})
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }

  componentDidMount() {
    this.requestPermission();
  }

  onMapTogglerClick(e) {
    this.setState({
      mobileMapVisible: !this.state.mobileMapVisible,
    });
  }

  onPopupCoverClick(e) {
    this.setState({
      popupVisible: false,
    });
  }

  onPopupToggle(e, optionalPosition) {
    let newPosition = {
      top: e.pageY,
      left: e.pageX,
    };
    if (optionalPosition) {
      newPosition = optionalPosition;
    }

    this.setState({
      popupPosition: newPosition,
      popupVisible: true,
    });
  }

  render() {
    if (window.location.pathname.indexOf("/guest") !== -1) {
      return (
        <AuthProvider authType={"localstorage"} authName={"_auth"}>
          <GuestSession />
        </AuthProvider>
      );
    }

    if (
      this.state.user.isGuest &&
      window.location.pathname.indexOf("/auth") === -1 &&
      window.location.pathname.indexOf("/reset-password") === -1
    ) {
      window.location = "/auth";
    }

    return (
      <>
        <AuthProvider user={this.state.user}>
          <BrowserRouter>
            <Routes>
              <Route path="/reset-password" element={<AuthPage />}>
                <Route index element={<AuthPageResetConfirmForm />} />
              </Route>
              <Route path="/auth" element={<AuthPage />}>
                <Route
                  index
                  element={<AuthPageLoginForm authHelper={this.authHelper} />}
                />
                <Route path="reset" element={<AuthPageResetForm />} />
                <Route
                  path="contact-us"
                  element={<AuthPageRegistrationForm />}
                />
                <Route path="logout" element={<AuthPageLogout />} />
                <Route path="*" element={<AuthPageLoginForm />} />
              </Route>

              <Route
                path="/"
                element={
                  <RequireAuth path={"/auth"}>
                    <GlobalProvider>
                      <MainContentLayout
                        loaderVisible={this.state.loaderVisible}
                        mapComponent={this.mapComponent}
                        onPopupToggle={this.onPopupToggle}
                        popupPosition={this.state.popupPosition}
                        popupVisible={this.state.popupVisible}
                      />
                    </GlobalProvider>
                  </RequireAuth>
                }
              >
                <Route
                  index
                  element={
                    <RequireAuth path={"/auth"}>
                      <InspectionsListPage
                        user={this.state.user}
                        mapComponent={this.mapComponent}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/inspections/:id"
                  element={
                    <RequireAuth path={"/auth"}>
                      <InspectionViewPage
                        user={this.state.user}
                        mapComponent={this.mapComponent}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/tracking/:id"
                  element={
                    <RequireAuth path={"/auth"}>
                      <TrackingViewPage user={this.state.user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/tracking/:id/:id/:id"
                  element={
                    <RequireAuth loginPath={"/auth"}>
                      <AnnotationDetails user={this.state.user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/tracking/:id/:id/:id/:id"
                  element={
                    <RequireAuth path={"/auth"}>
                      <AnnotationDetails user={this.state.user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/distribution/:id"
                  element={
                    <RequireAuth path={"/auth"}>
                      <DistributionViewPage user={this.state.user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/distribution/:id/:id/:id"
                  element={
                    <RequireAuth loginPath={"/auth"}>
                      <AnnotationDetails user={this.state.user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/distribution/:id/:id/:id/:id"
                  element={
                    <RequireAuth path={"/auth"}>
                      <AnnotationDetails user={this.state.user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/reports/"
                  element={
                    <RequireAuth path={"/auth"}>
                      <ReportsListPage
                        user={this.state.user}
                        mapComponent={this.mapComponent}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/reports/:id"
                  element={
                    <RequireAuth path={"/auth"}>
                      <ReportViewPage
                        user={this.state.user}
                        mapComponent={this.mapComponent}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <RequireAuth path={"/auth"}>
                      <SettingsPage
                        user={this.state.user}
                        mapComponent={this.mapComponent}
                      />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/orders/create"
                  element={
                    <RequireAuth path={"/auth"}>
                      <OrderCreatePage user={this.state.user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/uploads/images"
                  element={
                    <RequireAuth
                      path={"/accessdenied"}
                      permission={"can_upload_images"}
                    >
                      <GCPImagesUploadPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/images/list/:id?"
                  element={
                    <RequireAuth path={"/auth"}>
                      <ImagesListPage user={this.state.user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="accessdenied"
                  element={
                    <RequireAuth path={"/auth"}>
                      <NoAccessPage />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="*" element={<NoMatchPage />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
        <ToastContainer />
      </>
    );
  }
}

export default App;
