import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import leftArrow from "../assets/img/icons/chevron-black.png";
import rightArrow from "../assets/img/icons/chevron-black.png";
import disabledBackArrow from "../assets/img/icons/chevron-grey.png";
import disabledRightArrow from "../assets/img/icons/chevron-grey.png";

const NavigationComponent = ({
  selectedRequestId,
  selectedStructureNo,
  selectedStructureId,
  setSelectedRequestId,
  selectedItemId,
  setSelectedStructureNo,
  setSelectedStructureId,
  setSelectedItemId,
  displayNavigationButtons,
  setAnnotationWindowLoad,
  setDronePositionLoad,
}) => {
  const navigate = useNavigate();

  const [navigationData, setNavigationData] = useState(null);

  const fetchData = async () => {
    const data = await window.apiHelper.getStructuresToNavigate(
      selectedStructureId,
      selectedRequestId
    );
    setNavigationData(data);
  };

  useEffect(() => {
    window.location.pathname.includes("tracking") && fetchData();
  }, [selectedStructureNo]);

  const NavigateToStructure = async (val) => {
    try {
      setAnnotationWindowLoad(true);
      setDronePositionLoad(true);

      const newStructureNo = navigationData[`${val}_structure_name`];
      const newStructureId = navigationData[`${val}_structure_id`];

      setSelectedStructureId(newStructureId);
      setSelectedStructureNo(newStructureNo);
      const images = await window.apiHelper.getImagesList(
        selectedRequestId,
        navigationData[`${val}_structure_id`]
      );
      if (images.length > 0) {
        const imageDetails = await window.apiHelper.getPhotoAnnotationDetails(
          images[0].id
        );
        setSelectedItemId(imageDetails.id);
      } else {
        setSelectedItemId("");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const pathType = window.location.pathname.includes("tracking")
      ? "tracking"
      : "distribution";

    navigate(
      `/${pathType}/${selectedRequestId}/${selectedStructureNo}/${selectedStructureId}/${selectedItemId}`
    );
  }, [
    selectedRequestId,
    selectedStructureNo,
    selectedStructureId,
    selectedItemId,
    navigate,
    window.location.pathname,
  ]);

  return (
    <span>
      {navigationData?.previous_structure_id ? (
        <button
          onClick={() => NavigateToStructure("previous")}
          style={{
            background: "None",
            padding: "0",
            border: "None",
          }}
        >
          <img
            src={leftArrow}
            width="22.5"
            height="22.5"
            alt="arrow-left"
            style={{
              marginRight: "10px",
              marginBottom: "4px",
              transform: "rotate(90deg)",
            }}
            className="navigation-arrow"
          />
        </button>
      ) : (
        <img
          src={disabledBackArrow}
          width="22.5"
          height="22.5"
          alt="arrow-left"
          style={{
            display: `${!displayNavigationButtons && "None"}`,
            marginRight: "10px",
            marginBottom: "4px",
            transform: "rotate(90deg)",
          }}
        />
      )}
      {selectedStructureNo}
      {navigationData?.next_structure_id ? (
        <button
          onClick={() => NavigateToStructure("next")}
          style={{
            background: "None",
            padding: "0",
            border: "None",
          }}
        >
          <img
            src={rightArrow}
            width="22.5"
            height="22.5"
            alt="arrow-right"
            style={{
              marginLeft: "10px",
              marginBottom: "4px",
              transform: "rotate(-90deg)",
            }}
            className="navigation-arrow"
          />
        </button>
      ) : (
        <img
          src={disabledRightArrow}
          width="22.5"
          height="22.5"
          alt="arrow-left"
          style={{
            display: `${!displayNavigationButtons && "None"}`,
            marginLeft: "10px",
            marginBottom: "4px",
            transform: "rotate(-90deg)",
          }}
        />
      )}
    </span>
  );
};

export default NavigationComponent;
