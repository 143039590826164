import React from "react"
import { Autocomplete, TextField } from "@mui/material"

const AutocompleteForm = ({
    value,
    onChange,
    options,
    placeholder = "",
    disableClearable = true,
    autocompleteStyle = {},
    textStyle = {}
}) => {

    return (
        <Autocomplete
            value={value}
            onChange={onChange}
            options={options}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        sx: {
                            ...textStyle,
                            fontFamily: "'Space Grotesk', sans-serif",
                        },
                    }}
                    InputLabelProps={{
                        sx: {
                            fontFamily: "'Space Grotesk', sans-serif",
                        },
                    }}
                    sx={{
                        width: "200px",
                    }}
                />
            )}
            disableClearable={disableClearable}
            style={{ width: "auto", ...autocompleteStyle }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            // Styling for dropdowns 
            ListboxProps={{
                sx: {
                    fontFamily: "'Space Grotesk', sans-serif",
                },
            }}
        >

        </Autocomplete>
    )
}

export default AutocompleteForm