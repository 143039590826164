import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const NoMatchPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldRender, setShouldRender] = useState(false);
  const isRedirectingRef = useRef(false);

  useEffect(() => {
    let presentUrl = location.pathname;

    const match = presentUrl.match(/(distribution|tracking)/);

    if (match && !isRedirectingRef.current) {
      let keyword = match[0];

      let parts = presentUrl.split(keyword);
      let fixedPart = parts[1].replace(/\/{2,}/g, "/");
      let newUrl = `${parts[0]}${keyword}${fixedPart}`;

      if (newUrl !== presentUrl) {
        isRedirectingRef.current = true;
        navigate(newUrl, { replace: true });
      } else {
        setShouldRender(true);
      }
    } else {
      setShouldRender(true);
    }
  }, [location, navigate]);

  if (!shouldRender) {
    return null;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="no-page-container">
      <div className="no-page-box">
        <p className="no-page-text">404</p>
        <p className="no-page-sub-text">
          Page you are trying to access could not be found
        </p>
        <button
          type="button"
          className="btn btn-warning"
          onClick={handleGoBack}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NoMatchPage;
