import React, { useEffect, useRef, useState } from "react";
import { Marker } from "react-map-gl";
import currentMarker from "../../assets/img/icons/currentMarker.png";
import drone from "../../assets/img/icons/drone.png";
import infoIcon from "../../assets/img/icons/info.png";
import Tooltip from "@mui/material/Tooltip";
import "simplebar-react/dist/simplebar.min.css";
import { severityOptions, statusOptions } from "../../helpers/Constants";
import BaseMapComponent from "./BaseMap";
import MapDataSelector from "../../helpers/MapDataSelector";
import TextData from "../../assets/img/icons/text-data.png";
import Close from "../../assets/img/icons/cancel.png";

const NavigationMap = ({
  selectedItemId,
  mapToolHeight,
  selectedRequestId,
  setSelectedStructureNo,
  setSelectedStructureId,
  setSelectedItemId,
  setAnnotationWindowLoad,
  setStructureSummaryLoad,
}) => {
  const mapRef = useRef(null);

  const [selectedStructure, setSelectedStructure] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [mapZoom, setMapZoom] = useState(14);
  const [selectedData, setSelectedData] = useState(
    localStorage.getItem("selectedData") || "severity"
  );
  const [showDataLayers, setShowDataLayers] = useState(false);
  const [showLayers, setShowLayers] = useState(false);

  const handleStateReset = () => {
    setSelectedStructure(null);
  };

  const handleShowDataButton = () => {
    setShowDataLayers(!showDataLayers);
    localStorage.setItem("selectedData", selectedData);
    if (showLayers) {
      setShowLayers(false);
    }
  };

  const handleShowLayers = () => {
    setShowLayers(!showLayers);
    if (showDataLayers) {
      setShowDataLayers(false);
    }
  };

  const NavigateToStructure = async () => {
    try {
      setAnnotationWindowLoad(true);
      setStructureSummaryLoad(false);

      const newStructureNo = selectedStructure?.identifier;
      const newStructureId = selectedStructure?.id;

      setSelectedStructureId(newStructureId);
      setSelectedStructureNo(newStructureNo);
      const images = await window.apiHelper.getImagesList(
        selectedRequestId,
        selectedStructure?.id
      );
      if (images.length > 0) {
        const imageDetails = await window.apiHelper.getPhotoAnnotationDetails(
          images[0].id
        );
        setSelectedItemId(imageDetails.id);
      } else {
        setSelectedItemId("");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSelectedStructure(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleStateReset();
  }, [selectedItemId]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await window.apiHelper.getMapData(selectedItemId);
        setMapData(data);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedItemId]);

  useEffect(() => {
    if (selectedStructure) {
      NavigateToStructure();
    }
  }, [selectedStructure]);

  const getSeverityColor = (severityId) => {
    const severity = severityOptions.find((option) => option.id === severityId);
    return severity ? severity.color : "#848484"; // Default to good
  };

  const getStatusColor = (statusId) => {
    const status = statusOptions.find((option) => option.value === statusId);
    return status ? status.color : "#848484";
  };

  const getDataValue = (structure, selectedData) => {
    switch (selectedData) {
      case "severity":
        return getSeverityColor(structure.severity);
      case "status":
        return getStatusColor(structure.structure_status);
      default:
        return structure.severity;
    }
  };

  return (
    <>
      <BaseMapComponent
        innerRef={mapRef}
        mapData={mapData}
        loading={loading}
        mapToolHeight={mapToolHeight}
        onVisibleMarkersChange={setVisibleMarkers}
        setMapZoom={setMapZoom}
        showLayer={showLayers}
        toggleLayer={handleShowLayers}
      >
        <button className="data-button" onClick={handleShowDataButton}>
          <img
            src={!showDataLayers ? TextData : Close}
            alt="data"
            style={{ width: "25px", padding: "3px" }}
          />
        </button>
        {showDataLayers && (
          <div className="map-data-selector">
            <MapDataSelector
              setSelectedData={setSelectedData}
              selectedData={selectedData}
            />
          </div>
        )}
        {mapData?.image_metadata.pass.length > 0 && (
          <Marker
            latitude={mapData?.image_metadata.pass.lat}
            longitude={mapData?.image_metadata.pass.lon}
            anchor="center"
          >
            <div
              style={{
                transform: `rotate(${mapData?.image_metadata.pass.heading_degree}deg)`,
                pointerEvents: "none",
              }}
            >
              <img src={drone} alt="drone marker" />
            </div>
          </Marker>
        )}
        {mapZoom >= 15 &&
          mapData?.nearby_structures.length > 0 &&
          visibleMarkers.map((structure) => (
            <Marker
              key={structure?.identifier}
              latitude={structure?.lat}
              longitude={structure?.lon}
              anchor="center"
              onClick={() =>
                setSelectedStructure((prevSelected) =>
                  prevSelected === structure ? null : structure
                )
              }
            >
              <Tooltip
                title={structure.identifier}
                followCursor
                placement="top"
              >
                <div
                  className="dot-marker"
                  style={{
                    backgroundColor: getDataValue(structure, selectedData),
                  }}
                />
              </Tooltip>
            </Marker>
          ))}
        {mapData?.current_structure && (
          <Marker
            latitude={mapData?.current_structure?.lat}
            longitude={mapData?.current_structure?.lon}
            anchor="center"
          >
            <Tooltip
              title={mapData?.current_structure?.identifier}
              followCursor
              placement="top"
            >
              <img src={currentMarker} alt="current marker" />
            </Tooltip>
          </Marker>
        )}
        {mapZoom >= 15 && (
          <div className="map-info-button">
            <Tooltip
              title={
                "Select a structure to explore inspection images and details"
              }
              placement="top"
              arrow
            >
              <img
                src={infoIcon}
                alt="Info Icon"
                style={{ width: "24px", cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        )}
      </BaseMapComponent>
    </>
  );
};

export default NavigationMap;
