import React, { useEffect, useState, useRef } from "react";
import BaseMapComponent from "../../Components/Map/BaseMap";
import { Marker, Source, Layer } from "react-map-gl";
import { useNavigate } from "react-router-dom";
import * as turf from "@turf/turf";
import DropdownMenu from "../../helpers/DropdownMenu";
import { CustomTooltip, distribution_stats } from "../../helpers/Constants";
import Dropdown from "../../helpers/Dropdown";
import { chartData4 } from "../../helpers/Utils";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import { showNotificationToast } from "../../helpers/Messages";
import InfoPopup from "../inspections/InfoPopup";
import Polygon from "../../assets/img/icons/polygon.png";
import Reset from "../../assets/img/icons/reset.png";
import Search from "../../assets/img/search.svg";
import { Tooltip } from "@mui/material";

const DistributionMap = ({ id }) => {
  const navigate = useNavigate();

  const user_email = localStorage.getItem("user_email");
  const role_condition = localStorage.getItem("user_role") === "Admin";
  const mapRef = useRef(null);
  const parentRef = useRef(null);
  const inputRef = useRef(null);

  const [parentHeight, setParentHeight] = useState(600);

  const [mapData, setMapData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [mapZoom, setMapZoom] = useState(14);

  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [drawMode, setDrawMode] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentPoint, setCurrentPoint] = useState(null);
  const [count, setCount] = useState(0);

  const [hoveredMarker, setHoveredMarker] = useState(null);

  const [analyst, setAnalyst] = useState([]);
  const [reviewer, setReviewer] = useState([]);

  const [analysts, setAnalysts] = useState([]);
  const [reviewers, setReviewers] = useState([]);

  const [selectedAnalyst, setSelectedAnalyst] = useState(null);
  const [selectedReviewer, setSelectedReviewer] = useState(null);

  const [openPopup, setOpenPopup] = useState(false);

  const [highlightedMarkers, setHighlightedMarkers] = useState([]);

  const [selectedStats, setSelectedStats] = useState([]);
  const [search, setSearch] = useState("");
  const [distributionStats, setDistributionStats] = useState({});

  const [statusUpdate, setStatusUpdate] = useState(false);

  const handleClick = (item) => {
    setSelectedStats((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((i) => i !== item)
        : [...prevSelected, item]
    );
  };

  useEffect(() => {
    if (parentRef.current) {
      setParentHeight(parentRef.current.clientHeight);
    }

    const handleResize = () => {
      if (parentRef.current) {
        setParentHeight(parentRef.current.clientHeight);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const updateRemoteData = async () => {
    setLoading(true);
    try {
      const data = await window.apiHelper.getDistributionStructuresList(
        id,
        search,
        analyst,
        reviewer,
        selectedStats
      );
      data.length === 0 && setVisibleMarkers([]);
      setMapData(data);
    } catch (err) {
      if (err.response?.status === 404) {
        navigate("/accessdenied");
      }
    } finally {
      window.appComponent.setState({
        loaderVisible: false,
      });
      setLoading(false);
      setStatusUpdate(false);
    }
  };

  useEffect(() => {
    updateRemoteData();
  }, [search, analyst, reviewer, selectedStats, statusUpdate]);

  const getData = async () => {
    setLoading(true);
    try {
      const distribution_stats = await window.apiHelper.getDistributionStats(
        id
      );
      setDistributionStats(distribution_stats);

      const analysts = await window.apiHelper.getAnalysts();
      const reviewers = await window.apiHelper.getReviewers();

      const analystsData = analysts.map(({ id, name, email }) => ({
        id,
        label: name,
        value: email,
        name: name,
      }));

      const reviewersData = reviewers.map(({ id, name, email }) => ({
        id,
        label: name,
        value: email,
        name: name,
      }));

      setAnalysts(analystsData);
      setReviewers(reviewersData);
    } catch (err) {
      if (err.response?.status === 404) {
        navigate("/accessdenied");
      }
    } finally {
    }
  };

  useEffect(() => {
    getData();
  }, [statusUpdate]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.getCanvas().style.cursor = drawMode ? "crosshair" : "grab";
    }

    setTimeout(() => {
      if (mapRef.current) {
        const map = mapRef.current.getMap();
        map.getCanvas().style.cursor = drawMode ? "crosshair" : "grab";
      }
    }, 800);
  }, [drawMode, statusUpdate, loading]);

  const handleMapClick = (event) => {
    if (hoveredMarker) {
      setHoveredMarker(null);
      return;
    }
    if (drawMode) {
      const { lng, lat } = event.lngLat;
      setPolygonCoordinates((prev) => [...prev, [lng, lat]]);
      setCurrentPoint(null);
      setIsDrawing(true);
      calculateMarkersInside([...polygonCoordinates, [lng, lat]]);
    }
  };

  const handleMapDoubleClick = (event) => {
    if (isDrawing) {
      event.preventDefault();
      if (polygonCoordinates.length > 2) {
        const closedPolygon = [...polygonCoordinates, polygonCoordinates[0]];
        setPolygonCoordinates(closedPolygon);
        setIsDrawing(false);
      }
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (isDrawing) {
        map.doubleClickZoom.disable();
      } else {
        map.doubleClickZoom.enable();
      }
    }
  }, [isDrawing]);

  const handleMouseMove = (event) => {
    if (isDrawing) {
      const { lng, lat } = event.lngLat;
      setCurrentPoint([lng, lat]);
    }
  };

  const startDrawing = () => {
    setPolygonCoordinates([]);
    setCurrentPoint(null);
    setIsDrawing(true);
    setDrawMode(!drawMode);
    setHighlightedMarkers([]);
    setCount(0);
  };

  const calculateMarkersInside = (polygon) => {
    if (polygon.length < 3) {
      setCount(0);
      setHighlightedMarkers([]);
      return;
    }

    const closedPolygon = [...polygon, polygon[0]];
    const polygonGeoJSON = turf.polygon([closedPolygon]);
    let insideCount = 0;
    let highlighted = [];

    visibleMarkers.forEach((marker) => {
      const coordinates = [marker.location.lon, marker.location.lat];
      const point = turf.point(coordinates);
      if (turf.booleanPointInPolygon(point, polygonGeoJSON)) {
        insideCount++;
        highlighted.push(marker);
      }
    });

    setCount(insideCount);
    setHighlightedMarkers(highlighted);
  };

  const handleAnalystSelect = (selectedAnalyst) => {
    setAnalyst(selectedAnalyst);
  };

  const handleReviewerSelect = (selectedReviewer) => {
    setReviewer(selectedReviewer);
  };

  const handleStructureClick = async (structure) => {
    try {
      window.appComponent.setState({
        loaderVisible: true,
      });
      const images = await window.apiHelper.getImagesList(
        id,
        structure.structure_id
      );

      if (images.length > 0) {
        const imageDetails = await window.apiHelper.getPhotoAnnotationDetails(
          images[0].id
        );

        navigate(
          window.location.pathname +
            "/" +
            structure.name +
            "/" +
            structure.structure_id +
            "/" +
            imageDetails.id
        );
      } else {
        showNotificationToast("Structure has not been inspected yet");
      }
    } catch (e) {
      console.log(e);
    } finally {
      window.appComponent.setState({
        loaderVisible: false,
      });
    }
  };

  ChartJS.register(ArcElement, Legend);
  let chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const inputValue = inputRef.current.value;
    setSearch(inputValue);
  };

  const resetFilters = () => {
    setAnalyst([]);
    setReviewer([]);
    setMapData(null);
    setSearch("");
    inputRef.current.value = "";
    setSelectedStats([]);
  };

  const handleSave = async () => {
    try {
      const formattedData = highlightedMarkers.map((marker) => ({
        id: marker.id,
        status: 3,
        analyst: selectedAnalyst[0].value,
        reviewer: selectedReviewer[0].value,
      }));

      const data = await window.apiHelper.bulkUpdateStructures(formattedData);

      if (data) {
        setStatusUpdate(true);
        setCount(0);
        setHighlightedMarkers([]);
        setPolygonCoordinates([]);
      }
    } catch (error) {
      console.error("Error updating structures:", error);
    } finally {
    }
  };

  const handleShowAssignPopup = (structure) => {
    highlightedMarkers.push(structure);
    setCount(1);
  };

  const shouldHighlightStructure = (structure) => {
    const analystsEmail = structure.analyst.email;
    const reviewersEmail = structure.reviewer.email;

    return (
      analystsEmail === (user_email || reviewersEmail === user_email) &&
      structure.status === 3
    );
  };

  return (
    <div
      ref={parentRef}
      style={{
        position: "relative",
        height: "100%",
        boxShadow: drawMode ? "0 0 10px 3px #27c59b" : "none",
      }}
    >
      {openPopup && (
        <div style={{ position: "absolute", zIndex: "10" }}>
          <InfoPopup
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            selectedStructureId={hoveredMarker?.id}
            selectedRequestId={167}
            height={parentHeight}
          />
        </div>
      )}
      {role_condition && (
        <Tooltip title="Draw Polygon" arrow placement="top">
          <button
            className="draw-button"
            onClick={startDrawing}
            style={{
              backgroundColor: drawMode ? "#cacaca" : "white",
            }}
          >
            <img
              src={Polygon}
              alt="recenter"
              style={{ width: "25px", padding: "3px" }}
            />
          </button>
        </Tooltip>
      )}
      {role_condition && (
        <Tooltip title="Reset Filters" arrow placement="top">
          <button className="reset-button-distribution" onClick={resetFilters}>
            <img
              src={Reset}
              alt="reset"
              style={{ width: "25px", padding: "3px" }}
            />
          </button>
        </Tooltip>
      )}
      {count > 0 && role_condition && (
        <div className="count-display">
          <div
            style={{
              padding: "5px 10px 0px",
              borderRadius: "5px",
            }}
          >
            Towers inside area: {count}
          </div>

          <DropdownMenu
            placeholder={"Analyst"}
            options={analysts}
            selectedOption={setSelectedAnalyst}
          />

          <DropdownMenu
            placeholder={"Reviewer"}
            options={reviewers}
            selectedOption={setSelectedReviewer}
          />

          <button
            onClick={handleSave}
            className="show-info-button"
            style={{ width: "100px", margin: "8px", alignItems: "right" }}
          >
            Save
          </button>
        </div>
      )}
      {role_condition && (
        <div className="distribution-stats-box">
          <div className="distribution-stats">
            <Doughnut
              data={chartData4([
                distributionStats?.pending,
                distributionStats?.photos_uploaded,
                distributionStats?.assigned,
                distributionStats?.accepted,
                distributionStats?.reshoot,
                distributionStats?.overviewed,
              ])}
              options={chartOptions}
            />
            <div className="structure-stats-small">
              {distribution_stats.map((item) => (
                <span
                  key={item.label}
                  style={{
                    color: item.color,
                    textDecoration: selectedStats.includes(item.value)
                      ? "underline"
                      : "none",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectedStats.includes(item.value)}
                    onChange={() => handleClick(item.value)}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                  {item.label}: {distributionStats?.[item.key] || 0}
                </span>
              ))}
            </div>
          </div>
          <div className="multi-button-dropdown-analyst">
            <Dropdown
              options={analysts}
              optionSelected={handleAnalystSelect}
              selectedOptions={analyst}
              label="Select Analyst"
            />
          </div>

          <div className="multi-button-dropdown-reviewer">
            <Dropdown
              options={reviewers}
              optionSelected={handleReviewerSelect}
              selectedOptions={reviewer}
              label="Select Reviewer"
            />
          </div>
          <div className="distribution-search">
            <form
              action=""
              autoComplete="off"
              className="form-group search-group search"
              onSubmit={handleSearch}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Structure Search"
                id="annotation-search"
                ref={inputRef}
              />
              <button onClick={handleSearch}>
                <img src={Search} alt="Search" width="17" height="16" />
              </button>
            </form>
          </div>
        </div>
      )}
      <BaseMapComponent
        innerRef={mapRef}
        mapData={mapData}
        loading={loading}
        mapToolHeight={parentHeight + 21}
        onVisibleMarkersChange={setVisibleMarkers}
        setMapZoom={setMapZoom}
        onClick={handleMapClick}
        onDblClick={handleMapDoubleClick}
        onMouseMove={handleMouseMove}
      >
        {/* Render the polygon dynamically */}
        {polygonCoordinates.length > 0 && (
          <Source
            id="dynamicPolygonSource"
            type="geojson"
            data={{
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [
                  [
                    ...polygonCoordinates,
                    ...(currentPoint ? [currentPoint] : []),
                  ],
                ],
              },
            }}
          >
            <Layer
              id="dynamicPolygonLayer"
              type="fill"
              paint={{
                "fill-color": "rgba(0, 128, 255, 0.5)",
                "fill-outline-color": "blue",
              }}
            />
          </Source>
        )}

        {mapZoom >= 15 &&
          visibleMarkers.length > 0 &&
          visibleMarkers.map((structure) => {
            const isHovered = hoveredMarker?.id === structure?.id;
            const statusInfo = distribution_stats.find(
              (item) => item.value === structure.status
            );
            const statusLabel = statusInfo?.label;
            const markerColor = statusInfo?.color;
            return (
              <Marker
                key={structure?.id}
                latitude={structure?.location?.lat}
                longitude={structure?.location?.lon}
                anchor="center"
                onClick={() => {
                  if (!drawMode) {
                    handleStructureClick(structure);
                  } else {
                    handleShowAssignPopup(structure);
                  }
                }}
              >
                <CustomTooltip
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <div>Str. Name: {structure.name}</div>
                      <div>Status: {statusLabel}</div>
                      <div>Reviewer: {structure.reviewer.name || "N/A"}</div>
                      <div>Analyst: {structure.analyst.name || "N/A"}</div>
                      {structure.reviewer.name && structure.analyst.name ? (
                        <button
                          onClick={() => setOpenPopup(true)}
                          className="show-info-button"
                        >
                          Show Info
                        </button>
                      ) : (
                        <button className="show-info-disabled-button" disabled>
                          Show Info
                        </button>
                      )}
                    </div>
                  }
                  placement="bottom"
                  open={isHovered}
                >
                  <div
                    onMouseEnter={() => setHoveredMarker(structure)}
                    className="dot-marker"
                    style={{
                      backgroundColor: role_condition
                        ? markerColor
                        : shouldHighlightStructure(structure)
                        ? "#ff8039"
                        : "#808080",
                      outline: highlightedMarkers.some(
                        (m) => m.id === structure?.id
                      )
                        ? "3px solid orange"
                        : "none",
                      outlineOffset: "1px",
                    }}
                  />
                </CustomTooltip>
              </Marker>
            );
          })}
      </BaseMapComponent>
    </div>
  );
};

export default DistributionMap;
