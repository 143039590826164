import React from "react";
import { FormControl, OutlinedInput, Box, Chip } from "@mui/material";

const MultiInputForm = ({
    items = [],
    value,
    onChange,
    placeholder = "Add item",
    onAddItem,
    onRemoveItem,
    renderItem,
    formControlStyles = {},
    inputStyles = {},
    adornmentStyles = {},
}) => {
    return (
        <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            sx={{
                maxWidth: "100%", ...formControlStyles,
                fontFamily: "'Space Grotesk', sans-serif"
            }}
        >
            <OutlinedInput
                value={value}
                onChange={onChange}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && onAddItem) {
                        e.preventDefault();
                        onAddItem(value);
                    }
                }}
                sx={{
                    display: "flex", flexWrap: "wrap", ...inputStyles,
                    fontFamily: "'Space Grotesk', sans-serif"
                }}
                startAdornment={
                    items.length > 0 ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "4px",
                                maxWidth: "100%",
                                paddingTop: "10px",
                                ...adornmentStyles,
                            }}
                        >
                            {items.map((item, index) =>
                                renderItem ? (
                                    renderItem(item, index, onRemoveItem)
                                ) : (
                                    <Chip
                                        key={index}
                                        label={item}
                                        onDelete={() => onRemoveItem(index)}
                                        style={{
                                            margin: "2px",
                                            color: "black",
                                            backgroundColor: "#CFE2FB",
                                            fontSize: "15px",
                                            fontFamily: "'Space Grotesk', sans-serif"
                                        }}
                                    />
                                )
                            )}
                        </Box>
                    ) : null
                }
                placeholder={placeholder}
            />
        </FormControl>
    );
};

export default MultiInputForm;