import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
} from "@mui/material";
import Edit from "../../assets/img/icons/edit-black.png";
import EditUserDrawer from "./EditUserDrawer";
import Loader from "../../helpers/Loader";

const columns = [
  { id: "email", label: "Email", minWidth: 280 },
  { id: "org", label: "Organization", minWidth: 100 },
  { id: "role", label: "Role", minWidth: 150 },
  { id: "active", label: "Active", minWidth: 100, align: "center" },
  { id: "requests", label: "Requests", minWidth: 170, align: "center" },
  { id: "actions", label: "Actions", minWidth: 100, align: "center" },
];

const TeamsTable = ({ searchText, role, org, rolesList, teamsTableLoad }) => {
  const currentUserOrgId = localStorage.getItem("user_org_id");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [tableLoad, setTableLoad] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [updateUserData, setUpdateUserData] = useState(false);

  const fetchData = async () => {
    setUpdateUserData(false);
    try {
      setTableLoad(true);
      const tableList = await window.apiHelper.getTeamsTableData(
        currentUserOrgId,
        page + 1,
        rowsPerPage,
        searchText,
        role,
        org
      );

      setTableData(tableList);
      setApiError(false);
    } catch (error) {
      console.log(error);
      setApiError(true);
    } finally {
      setTableLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    rowsPerPage,
    page,
    searchText,
    role,
    org,
    updateUserData,
    teamsTableLoad,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleActionClick = (user) => {
    setSelectedUser(user);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedUser(null);
  };

  const handleFieldChange = (field, value) => {
    setSelectedUser((prev) => ({ ...prev, [field]: value }));
  };

  const handleRequestIdChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedUser((prev) => ({
      ...prev,
      request_ids: typeof value === "string" ? value.split(",") : value,
    }));
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        height: "calc(100% - 140px)",
        position: "relative",
      }}
    >
      <TableContainer sx={{ height: "calc(100% - 51px)" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: "#EEF1F8",
                    fontFamily: "inherit",
                    fontWeight: 600,
                    fontSize: "14.5px",
                    textAlign: "center",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {tableLoad ? (
            <Loader />
          ) : (
            <TableBody>
              {tableData.results &&
                tableData.results.map((user) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                    {columns.map((column) => {
                      let value = user[column.id];

                      if (column.id === "requests") {
                        value = user.request_ids;
                      }
                      if (column.id === "active") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Chip
                              label={value ? "Active" : "Inactive"}
                              sx={{
                                backgroundColor: value ? "#ddffcd" : "#ffd7d7",
                                color: value ? "#007f00" : "#dd2c00",
                                border: value
                                  ? "1px solid #007f00"
                                  : "1px solid #dd2c00",
                              }}
                            />
                          </TableCell>
                        );
                      } else if (column.id === "requests") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <div
                              style={{
                                display: "flex",
                                gap: "4px",
                                flexWrap: "wrap",
                                justifyContent: "center",
                              }}
                            >
                              {value?.length > 30 ? (
                                <>
                                  {value.slice(0, 29).map((requestId) => (
                                    <Chip
                                      key={requestId}
                                      label={`${requestId}`}
                                      variant="outlined"
                                    />
                                  ))}
                                  <Chip
                                    label={`+${value.length - 29} more`}
                                    variant="filled"
                                    sx={{
                                      backgroundColor: "#E0E0E0",
                                      fontWeight: 600,
                                    }}
                                  />
                                </>
                              ) : (
                                value.map((requestId) => (
                                  <Chip
                                    key={requestId}
                                    label={`${requestId}`}
                                    variant="outlined"
                                  />
                                ))
                              )}
                            </div>
                          </TableCell>
                        );
                      } else if (column.id === "actions") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <img
                              src={Edit}
                              alt="edit"
                              style={{ width: "22.5px", cursor: "pointer" }}
                              onClick={() => handleActionClick(user)}
                            />
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              maxWidth: column.minWidth,
                              color: "inherit",
                              fontFamily: "inherit",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 150]}
        component="div"
        count={tableData.count ? tableData.count : 1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .MuiTablePagination-selectLabel": {
            margin: 0,
          },
          "& .MuiTablePagination-displayedRows": {
            margin: 0,
          },
        }}
      />

      {selectedUser && (
        <EditUserDrawer
          drawerOpen={drawerOpen}
          selectedUser={selectedUser}
          handleDrawerClose={handleDrawerClose}
          handleFieldChange={handleFieldChange}
          handleRequestIdChange={handleRequestIdChange}
          rolesList={rolesList}
          setUpdateUserData={setUpdateUserData}
          setSelectedUser={setSelectedUser}
        />
      )}
    </Paper>
  );
};

export default TeamsTable;
