import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Box,
  TablePagination,
} from "@mui/material";
import Edit from "../../assets/img/icons/edit-black.png";
import Loader from "../../helpers/Loader";
import EditRequestDrawer from "./EditRequestDrawer";

const columns = [
  { id: "id", label: "Request", minWidth: 100 },
  { id: "line_number", label: "Line No", minWidth: 150 },
  { id: "created_at", label: "Date", minWidth: 100 },
  { id: "org", label: "Organization", minWidth: 100 },
  { id: "users", label: "Users", minWidth: 200 },
  { id: "actions", label: "Actions", minWidth: 50 },
];

const RequestTable = ({ searchText }) => {
  const [editableRow, setEditableRow] = useState(null);
  const [editedUsers, setEditedUsers] = useState({});
  const currentUserOrgId = localStorage.getItem("user_org_id");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [tableLoad, setTableLoad] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [updateUserData, setUpdateUserData] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const fetchData = async () => {
    setUpdateUserData(false);
    try {
      setTableLoad(true);
      const tableList = await window.apiHelper.getRequestsTableData(
        currentUserOrgId,
        page + 1,
        rowsPerPage,
        searchText
      );

      setTableData(tableList);
      setApiError(false);
    } catch (error) {
      console.log(error);
      setApiError(true);
    } finally {
      setTableLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [rowsPerPage, page, searchText, updateUserData]);

  const handleEditClick = (data) => {
    const selectedRequest = tableData.results.find(
      (request) => request.id === data.id
    );
    setEditableRow(data);
    setEditedUsers(selectedRequest.users);
    setDrawerOpen(true);
  };

  const handleAddEmail = (email) => {
    if (email.trim() && !editedUsers.includes(email)) {
      setEditedUsers((prev) => [...prev, email]);
    }
  };

  const handleDeleteUser = (userIndex) => {
    setEditedUsers((prevUsers) =>
      prevUsers.filter((_, index) => index !== userIndex)
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        height: "calc(100% - 80px)",
        position: "relative",
      }}
    >
      <TableContainer sx={{ height: "calc(100% - 51px)" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    background: "#EEF1F8",
                    fontFamily: "inherit",
                    fontWeight: 600,
                    fontSize: "14.5px",
                    textAlign: "center",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {tableLoad ? (
            <Loader />
          ) : (
            <TableBody>
              {tableData.results &&
                tableData.results.map((request) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={request.id}
                  >
                    {columns.map((column) => {
                      const value = request[column.id];
                      if (column.id === "users") {
                        return (
                          <TableCell key={column.id}>
                            <Box display="flex" gap={1} flexWrap="wrap">
                              {value.map((user) => (
                                <Chip
                                  key={user}
                                  label={user}
                                  style={{
                                    margin: "2px",
                                    color: "black",
                                    backgroundColor: "#CFE2FB",
                                    fontSize: "15px",
                                  }}
                                />
                              ))}
                            </Box>
                          </TableCell>
                        );
                      } else if (column.id === "actions") {
                        return (
                          <TableCell key={column.id} align="center">
                            <img
                              src={Edit}
                              alt="edit"
                              style={{ width: "22.5px", cursor: "pointer" }}
                              onClick={() => handleEditClick(request)}
                            />
                          </TableCell>
                        );
                      } else if (column.id === "created_at") {
                        return (
                          <TableCell
                            style={{
                              maxWidth: column.minWidth,
                              color: "inherit",
                              fontFamily: "inherit",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            {new Date(value).toLocaleString("en-US", {
                              month: "short",
                              year: "numeric",
                            })}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            style={{
                              maxWidth: column.minWidth,
                              color: "inherit",
                              fontFamily: "inherit",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 150]}
        component="div"
        count={tableData.count ? tableData.count : 1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .MuiTablePagination-selectLabel": {
            margin: 0,
          },
          "& .MuiTablePagination-displayedRows": {
            margin: 0,
          },
        }}
      />

      {editableRow && (
        <EditRequestDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          editedUsers={editedUsers}
          handleDeleteUser={handleDeleteUser}
          handleAddEmail={handleAddEmail}
          editableRow={editableRow}
          setEditableRow={setEditableRow}
          setUpdateUserData={setUpdateUserData}
        />
      )}
    </Paper>
  );
};

export default RequestTable;
