import React from "react";
import { useNavigate } from "react-router-dom";
import previewSrc from "./../../assets/img/demo.jpg";
import { getDateFormatted } from "../../helpers/Utils";
import { useGlobal } from "../../GlobalContext";

const InspectionPageNavigate = "/inspections/";
const TrackingPageNavigate = "/tracking/";
const DistributionPageNavigate = "/distribution/";

const InspectionCard = ({ data }) => {
  const { setOrg } = useGlobal();

  const cardTitle = data.line_number;
  const cardDate = getDateFormatted(data?.created_at);
  const requestId = data.id;

  const navigate = useNavigate();

  const onTrackingClick = (e) => {
    e.preventDefault();
    setOrg(data.org);
    if (data?.request_type === 2) {
      navigate(DistributionPageNavigate + requestId);
    } else if (data?.request_type === 1) {
      navigate(TrackingPageNavigate + requestId);
    }
  };

  return (
    <>
      <div className="inspection-card" onClick={onTrackingClick}>
        <div className="card-preview">
          {data.thumbnails.length > 0 ? (
            <>
              {data.thumbnails.map((thumbnail, index) => (
                <img key={index} src={thumbnail} className="multiImg" />
              ))}
            </>
          ) : (
            <img src={previewSrc} className="singleImg" />
          )}
        </div>
        <div className="card-info">
          <h3 className="card-text-top">{cardTitle}</h3>
          <div className="card-text-bottom">
            <p>{cardDate}</p>
            <h5>{requestId}</h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default InspectionCard;
