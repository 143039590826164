// PopupModal.js
import React, { useState } from "react";
import { Dialog, DialogTitle, Button } from "@mui/material";
import PopupInternal from "./PopupInternal";
import PopupExternal from "./PopupExternal";

const PopupModal = ({ open, onClose, rolesList, setTeamsTableLoad }) => {
  const buttons = ["Internal", "External"];

  const [activeTab, setActiveTab] = useState(buttons[0]);
  const [internalEmails, setInternalEmails] = useState([]);
  const [externalEmails, setExternalEmails] = useState([]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          height: "80vh",
          width: "80vw",
          padding: "16px",
        },
      }}
    >
      {/* <DialogTitle>Invite Users</DialogTitle> */}

      {/* Toggle Buttons */}
      <div className="toggle-buttons">
        {buttons.map((button) => (
          <button
            key={button}
            className={`toggle-button ${activeTab === button ? "active" : ""}`}
            onClick={() => setActiveTab(button)}
          >
            {button}
          </button>
        ))}
      </div>

      {/* Content based on active tab */}
      {activeTab === "Internal" && (
        <PopupInternal
          internalEmails={internalEmails}
          setInternalEmails={setInternalEmails}
          onClose={onClose}
          rolesList={rolesList}
          setTeamsTableLoad={setTeamsTableLoad}
        />
      )}
      {activeTab === "External" && (
        <PopupExternal
          externalEmails={externalEmails}
          setExternalEmails={setExternalEmails}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
};

export default PopupModal;
