import React, { useContext, useEffect, useRef, useState } from "react";
import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../../helpers/Firebase";
import { useGlobal } from "../../GlobalContext";
import Send from "../../assets/img/icons/send.png";
import AuthContext from "../auth/AuthContext";
import Sending from "../../helpers/Sending";
import Loader from "../../helpers/Loader";

const ChatBox = ({ selectedItemId, summarySelected, structureDetails }) => {
  const { org } = useGlobal();

  const userEmail = localStorage.getItem("user_email");
  const userRole = localStorage.getItem("user_role");

  const isAuthorized =
    userRole === "Admin" ||
    userRole === "Operations" ||
    structureDetails?.analyst?.email === userEmail ||
    structureDetails?.reviewer?.email === userEmail;

  const checkUrl = (url) => {
    const match = url.match(/\/\/(.*?)\./);
    if (match && match[1] === "detect-inspections") {
      return true;
    }
    return false;
  };

  const query =
    summarySelected === "chat"
      ? checkUrl(window.location.href)
        ? collection(db, "detect-chat", org, selectedItemId)
        : collection(db, "detect-chat-stage", org, selectedItemId)
      : checkUrl(window.location.href)
      ? collection(db, "detect-staff-chat", org, selectedItemId)
      : collection(db, "detect-staff-chat-stage", org, selectedItemId);
  const [values, loading, error] = useCollectionData(query);

  const authContext = useContext(AuthContext);

  const [localMessages, setLocalMessages] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const currentDateTimeUTC = new Date().toISOString();

  const formattedDate = currentDateTimeUTC
    .replace("T", " ")
    .replace(/\.\d+Z$/, "+00:00");

  const handleInput = (event) => {
    const textarea = event.target;
    textarea.style.height = 0;
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;

    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (textarea.value.trim() !== "") {
        handleSendButtonClick(event);
      }
    }
  };

  useEffect(() => {
    if (values) {
      const updatedLocalMessages = values.map(
        ({ user_email, msg, sent_at, user_name }) => ({
          user_email,
          msg,
          sent_at,
          user_name,
          new_msg: false,
          failed: false,
        })
      );

      const errorMessages = getObjectsById(selectedItemId);

      const allMessages = [...updatedLocalMessages, ...errorMessages];

      allMessages.sort((a, b) => {
        const timeA = new Date(a.sent_at).getTime();
        const timeB = new Date(b.sent_at).getTime();
        return timeA - timeB;
      });
      setLocalMessages(allMessages);
      setDisabled(false);
    }
  }, [values, errorMessages]);

  const generateUniqueId = () => {
    return Math.random().toString(36).substring(7);
  };

  const getObjectsById = (id) => {
    const foundItem = errorMessages.find((item) => item.id === id);
    return foundItem ? foundItem.messages : [];
  };

  const getOrCreateObjectsById = (id, initialObject = {}) => {
    let foundItem = errorMessages.find((item) => item.id === id);

    if (!foundItem) {
      foundItem = { id: id, messages: [initialObject] };
      setErrorMessages((prevData) => [...prevData, foundItem]);
    } else {
      setErrorMessages((prevData) =>
        prevData.map((item) =>
          item.id === id
            ? { ...item, messages: [...item.messages, initialObject] }
            : item
        )
      );
    }
    return foundItem;
  };

  const handleSendButtonClick = async (e) => {
    if (!isAuthorized) {
      return;
    }
    setDisabled(true);
    e.preventDefault();
    const inputValue = document.querySelector("textarea#chat-message").value;
    const logObject = {
      image_id: selectedItemId,
      msg: inputValue,
    };

    if (!inputValue) {
      setDisabled(false); // Re-enable the button if no message is typed
      return;
    }

    const uniqueId = generateUniqueId();

    const msg_to_be_sent = {
      user_email: authContext.email,
      msg: inputValue,
      new_msg: true,
      failed: false,
      uniqueId: uniqueId,
      sent_at: formattedDate,
    };

    if (logObject.msg) {
      setLocalMessages((prevMessages) => [...prevMessages, msg_to_be_sent]);
      document.querySelector("textarea#chat-message").value = null;
      document.querySelector("textarea#chat-message").style.height = 0;
      const result =
        summarySelected === "chat"
          ? await window.apiHelper.postInputChatData(logObject)
          : await window.apiHelper.postInputInternalChatData(logObject);

      if (!result) {
        msg_to_be_sent.failed = true;
        getOrCreateObjectsById(selectedItemId, msg_to_be_sent);
        setDisabled(false);
      }
    }
  };

  const chatContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat container
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [localMessages]);

  const handleRetry = async (msgDetails) => {
    setDisabled(true);
    const updatedMessages = localMessages.map((message) =>
      message.uniqueId === msgDetails.uniqueId
        ? { ...message, failed: false }
        : message
    );

    setLocalMessages(updatedMessages);

    const logObject = {
      image_id: selectedItemId,
      msg: msgDetails.msg,
    };
    const result =
      summarySelected === "chat"
        ? await window.apiHelper.postInputChatData(logObject)
        : await window.apiHelper.postInputInternalChatData(logObject);
    if (!result) {
      const updatedMessages = localMessages.map((message) =>
        message.uniqueId === msgDetails.uniqueId
          ? { ...message, failed: true }
          : message
      );

      setLocalMessages(updatedMessages);
      setDisabled(false);
    } else {
      const errorMessages = getObjectsById(selectedItemId);

      const updatedData = errorMessages.filter(
        (message) => message.uniqueId !== msgDetails.uniqueId
      );

      setErrorMessages(updatedData);
    }
  };

  if (selectedItemId === undefined || selectedItemId === "") {
    return;
  }

  return (
    <div className="chat">
      {loading ? (
        <div className="emptyContainer">
          <Loader />
        </div>
      ) : (
        <>
          {localMessages?.length > 0 ? (
            <div className="chatContainer" ref={chatContainerRef}>
              {localMessages?.map((message, index) => (
                <div
                  key={index}
                  className={`message ${
                    message.user_email === authContext.email
                      ? "sent"
                      : "received"
                  }`}
                >
                  <div className="message-name">
                    {message.user_name
                      ? message.user_name
                      : message.user_email.split(".").slice(0, -1).join(".")}
                  </div>
                  <div className="message-content">
                    {message.msg.split("\n").map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                  </div>

                  {message.new_msg ? (
                    message.failed ? (
                      <div className="retry-block">
                        <div
                          className="try-again"
                          onClick={() => handleRetry(message)}
                        >
                          Try Again
                        </div>
                        <div className="failed">failed</div>
                      </div>
                    ) : (
                      <Sending />
                    )
                  ) : null}
                </div>
              ))}
            </div>
          ) : (
            <div className="emptyContainer">
              <div className="no-messages">No messages yet</div>
            </div>
          )}
        </>
      )}

      <div className="newMessage">
        <form
          action=""
          autoComplete="off"
          className="msg-group"
          onSubmit={handleSendButtonClick}
          onKeyUp={handleInput}
        >
          <div className="text-area-container">
            <textarea
              className="form-control"
              placeholder={
                isAuthorized
                  ? "Type your message"
                  : "No permission to send messages"
              }
              disabled={!isAuthorized || disabled}
              rows="1"
              id="chat-message"
              style={{ border: "none" }}
            />
          </div>
          <button onClick={handleSendButtonClick} className="send-button">
            <img src={Send} alt="Image Alt Text" className="send-img" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatBox;
